/* AdminSuccessStoriesPage.css */

.admin-page {
    padding: 20px;
  }
  
  .story-form {
    margin-top: 20px;
  }
  
  h2 {
    font-size: 20px;
  }
  
  .story-form input[type="text"],
  .story-form input[type="date"],
  .story-form textarea {
    margin-bottom: 10px;
    padding: 5px;
    width: 100%;
  }
  
  .story-form input[type="file"] {
    margin-bottom: 10px;
    padding: 5px;
    width: 100%;
  }
  
  button {
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 1rem;
    margin-right: 1rem;
    /* background-color: #007bff; You can choose your preferred button color */
  }
  
  .button:hover {
    /* background-color: #0056b3; Button color on hover */
  }
  
  ul {
    list-style: none;
  }
  
  .story-list li {
    border: 1px solid #ccc;
    margin: 10px 0;
    padding: 10px;
  }
  
  .story-list img {
    max-width: 2rem;
    height: auto;
  }
  
  /* You can add more styling as needed based on your design preferences */
  