.admin-page {
    padding: 20px;
  }
  
  .section {
    margin-top: 20px;
  }
  
  h2 {
    font-size: 20px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  
  input {
    margin-bottom: 10px;
    padding: 5px;
  }
  
  button {
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  
  ul {
    list-style: none;
  }
  
  /* li {
    font-size: 16px;
    margin-bottom: 10px;
  }
   */