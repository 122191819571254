/* Global styles */
.procedures-done-container {
  max-width: 1400px; /* Adjust the max-width to match other pages' widths */
  /* margin: 2rem auto; Center the container horizontally */
  padding: 20px;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
}

.procedures-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  margin: 0;
}


.titleProcedure {
  font-size: clamp(2rem, 6vw + 1rem, 2rem);
	font-weight: 900;
  margin-bottom: 2rem;
  font-family: Poppins;
  font-weight: 600;
  background: var(--global-text-gradient);
	color: transparent;
	-webkit-background-clip: text;
}
.procedure-item {
  animation: fadeInUp 3s ease;
  flex: 0 1 calc(33.33% - 20px);
  margin: 10px;
  color: rgb(48, 45, 45);
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  /* background: linear-gradient(109.6deg, rgb(78, 62, 255) 11.2%, rgb(164, 69, 255) 91.1%); */
  font-size: 18px; /* Adjust the font size here */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.procedure-item:hover {
  transform: scale(1.01);
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); */
  background-image: linear-gradient(
    106.8deg,
    rgb(117, 255, 220) 6%,
    rgb(163, 216, 255) 47.6%,
    rgb(248, 215, 251) 87.8%
  );
  box-shadow: 7px 7px 7px 7px rgba(255, 197, 191, 0.72);
}

/* Responsive Styles for iPhone 7 and 11 screens */
@media screen and (max-width: 480px) {
  .procedures-done-container {
    max-width: 95%; /* Adjust the max-width for smaller screens */
    margin-bottom: auto;
  }

  .procedures-done-heading {
    font-size: 28px;
  }

  .procedure-item {
    flex: 0 1 calc(95% - 20px);
    font-size: 16px; /* Adjust the font size for smaller screens */
  }
}
