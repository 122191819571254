/* Global styles */
.services-container {
  animation: fadeInUp 1s ease;
  text-align: center;
  padding: 2rem;
  background-color: whitesmoke;
  height: auto;
  margin-bottom: 2rem;
}

.titleSERV{
  animation: fadeInUp 2s ease;
  font-size: 2rem;
  margin-bottom: 2rem;
  font-family: Poppins;
  font-weight: bolder;
  background: var(--global-text-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Service grid layout */
.services-grid {
  animation: fadeInUp 3s ease;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 2rem;
  justify-items: center;
}

/* Individual service cards */
.service-card {
  animation: fadeInUp 1s ease;
  background: linear-gradient(to bottom, #ffffff, #f4f4f4);
  border-radius: 0px;
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out 0.2s;
  /* Remove min-width property to keep the size the same */
  /* min-width: 280px; */
  width: 280px; /* Set a fixed width for all cards */
  cursor: pointer;
  position: relative;
  overflow: hidden;
  box-shadow: 10px 10px 10px 10px rgba(201, 197, 191, 0.42);
}

/* Hover styles for service cards */
.service-card:hover {
  transform: translateY(-5px);
  opacity: 0.9;
  background-image: linear-gradient(
    106.8deg,
    rgb(117, 255, 220) 6%,
    rgb(163, 216, 255) 47.6%,
    rgb(248, 215, 251) 87.8%
  );
  box-shadow: 10px 10px 10px 10px rgba(255, 197, 191, 0.72);
}

/* Service card headings */
.service-card h2 {
  font-size: 18px;
  color: rgb(41, 46, 42);
  /* Add more styles as needed */
}

/* Initially hide the description */
.service-card p {
  font-size: 14px;
  color: #050223;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%; /* Set the width to 100% to cover the entire card */
  text-align: center; /* Center the text horizontally */
  z-index: 1;
}

/* Show the description on hover */
.service-card:hover p {
  opacity: 1;
}

/* Hide the heading on hover */
.service-card:hover h2 {
  visibility: hidden;
}

/* Responsive styles */

/* Example media query for smaller screens */
@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .service-card {
    /* Set a fixed width for cards on smaller screens */
    width: 250px;
  }
  /* Individual service cards */
.service-card {
  box-shadow: 8px 8px 8px 8px rgba(201, 197, 191, 0.42);
}

/* Hover styles for service cards */
.service-card:hover {
  box-shadow: 8px 8px 8px 8px rgba(255, 197, 191, 0.72);
}
}

/* Mobile-friendly styles */
@media (max-width: 480px) {
  .service-card {
    /* Set a fixed width for cards on mobile devices */
    width: 100%;
  }
    /* Individual service cards */
.service-card {
  box-shadow: 8px 8px 8px 8px rgba(201, 197, 191, 0.42);
}

/* Hover styles for service cards */
.service-card:hover {
  box-shadow: 8px 8px 8px 8px rgba(255, 197, 191, 0.72);
}
.page-title {
  font-size: 2rem;
}

.title2{
  font-size: 1.5rem;
}
}
