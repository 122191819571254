/* DoctorsPage.css */

.doctors-page {
  padding: 1rem;
  text-align: center;
  font-family: 'Arial', sans-serif;
  position: relative;
  /* background-image: url(/public/blur-hospital.jpg); */
  background-size: cover;
  background-position: center;
  overflow: hidden;
  min-height: 30vh;
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

header {
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  padding: 1rem;
  min-width: 90%;
  height: auto;
  position: relative;
}

h1 {
  font-size: 2rem;
  transition: transform 0.5s;
  color: #000;
  font-weight: bold;
}

.blurdoc {
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background: #edd0ff;
  filter: blur(212px);
  z-index: -9;
  top: -18%;
  left: 56%;
  filter: blur(72px);
}

.titleDoc {
  font-size: clamp(2rem, 6vw + 1rem, 2rem);
  font-weight: 900;
  margin-bottom: 0%;
  font-family: Poppins;
  font-weight: 600;
  background: var(--global-text-gradient);
  color: transparent;
  -webkit-background-clip: text;
}

.UDL {
  display: flex;
  flex-wrap: wrap; /* Change flex-wrap to nowrap to ensure a row layout */
  justify-content: center;
  flex-direction: row;
  margin-top: auto;
}

.doctor {
  flex-direction: row;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  margin: 1rem;
  padding: 0.5rem;
  padding-top: 2rem;
  max-width: auto;
  position: relative;
  opacity: 0;
  transform: translateY(20px);
  transition: transform 3s, opacity 0.5s, background 0.5s;
  border-radius: 0px;
  text-align: center;
  cursor: pointer;
}

.doctor:hover {
  transform: scale(1.05);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  background: linear-gradient(110.8deg, rgb(243, 243, 243) 11.4%, rgb(175, 231, 181) 84.5%);
}


.doctor img {
  max-width: 50%;
  border-radius: 0px;
}

h2 {
  font-size: 1.2rem;
  margin-top: 1rem;
  font-weight: bold;
  color: #000;
}

.doctor-details {
  text-align: center;
}

.doctor-name {
  font-size: 0.9rem;
  margin: 0;
  font-weight: bold;
  margin-bottom:auto;
  font-family: Poppins;
  font-weight: 600;
  background: var(--global-text-gradient);
  color: transparent;
  -webkit-background-clip: text;
}

.doctor-specialty {
  font-size: 0.8rem;
  color: #777;
  margin: 0;
}

p {
  font-size: 1.4rem;
  color: #777;
  margin: 1rem 0;
}

.animate-from-left.in-view,
.animate-from-right.in-view {
  transform: translateY(0);
  opacity: 1;
}

/* Base styles for screens smaller than 768px (typical for iPhone 7) */
@media (max-width: 767px) {
  .doctors-page {
    height: auto;
    padding-bottom: 5rem;
  }

  .doctor {
    max-width: 100%;
  }
}

/* Styles for screens between 768px and 1023px (adjust as needed) */
@media (min-width: 768px) and (max-width: 1023px) {
  .doctor {
    max-width: calc(50% - 1rem);
  }
}

/* Styles for screens larger than 1023px (typical for iPhone 11 and above) */
@media (min-width: 1024px) {
  .doctors-page {
    padding: 3rem;
  }

  .doctor {
    max-width: 300px;
  }
}

/* Responsive Styles for screens with a max-width of 480px (e.g., iPhone 11) */
@media screen and (max-width: 480px) {
  .doctors-page {
    height: auto;
  }

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1.4rem;
  }
  .doctors-page {
    margin-bottom: auto;
  }
  .blurdoc {
   display: none;
  }
  
}
