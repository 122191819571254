/* EUTiming.css */

.TYMcontainer {
  animation: fadeIn 1s ease-in-out;
  padding: 1rem;
  text-align: center;
  font-family: 'Arial', sans-serif;
  position: relative;
  /* background-image: url(/public/blur-hospital.jpg); */
  /* background: linear-gradient(230deg, rgb(234, 234, 234) 22.3%, rgb(167, 232, 172) 56.6%, rgb(234, 234, 234)  90.9%); */
  background: linear-gradient(113.5deg, rgb(234, 234, 234) 22.3%, rgb(201, 234, 211) 56.6%, rgb(255, 180, 189) 90.9%);
  background-size: cover;
  background-position: center;
  overflow: hidden;
  min-height: 30vh;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.TYMheading {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.TYMstamp {
  font-size: 16px;
  color: #ccc;
  margin-left: 10px;
}

.TYMsection {
  margin-top: 20px;
}

.timing-item {
  animation: fadeIn 2s ease-in-out;
  background: white;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display:block;
  justify-content: space-between;
  align-items:flex-start;
}

.TYMdocName {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  background: -webkit-linear-gradient(
    219.3deg,
    rgb(149, 0, 199) 4.7%,
    rgb(130, 0, 226) 22.9%,
    rgb(95, 0, 199) 43.2%,
    rgb(70, 0, 199) 63.9%,
    rgb(5, 0, 199) 85.1%,
    rgb(0, 95, 199) 106.8%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.TYMavail {
  font-size: 14px;
  color: grey;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

/* Add hover effect to each list item */
.timing-item:hover {
  background: linear-gradient(135deg, #f0f0f0, #ddd);
  transition: background 0.3s ease;
}
