/* ViewAppointments.css */
.AppointmentsView {
  animation: fadeIn 1s ease-in-out;
  text-align: center;
}

.categories {
  animation: fadeIn 1s ease-in-out;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.category-heading {
  font-size: 0.9rem;
  background: linear-gradient(179.4deg, rgb(12, 20, 69) -16.9%, rgb(71, 30, 84) 119.9%);
  padding: 0.5rem;
  margin: 1rem 0;
  text-align: center;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.appointments-table {
  width: 100%;
  border-collapse: collapse;
  background: whitesmoke;
  margin-top: 1rem;
  color: black;
  table-layout: fixed;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.table-header {
  padding: 0.5rem;
  background-color:darkcyan;
  font-weight: bold;
  font-size: 0.7rem;
  border: 1px solid #ccc;
}

.table-data {
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  word-wrap: break-word;
  text-align: center;
  font-size: 0.65rem;
}

/* Responsive Styles for iPhone 11 */
@media (max-width: 414px) {
  .category-heading {
    font-size: 1.2rem;
  }

  .table-header,
  .table-data {
    padding: 0.3rem 0.6rem;
  }
}

/* Additional Media Queries for Smaller Devices */
@media (max-width: 480px) {
  .category-heading {
    font-size: 1rem;
  }

  .table-header,
  .table-data {
    padding: 0.2rem 0.4rem;
  }

  .category-heading {
    font-size: 0.9rem;
    background: linear-gradient(179.4deg, rgb(12, 20, 69) -16.9%, rgb(71, 30, 84) 119.9%);
    padding: 0.5rem;
    margin: 1rem 0;
    text-align: center;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .appointments-table {
    width: 100%;
    border-collapse: collapse;
    background: linear-gradient(69.5deg, rgb(246, 243, 243) -0.5%, rgb(3, 10, 252) 97.4%);
    margin-top: 1rem;
    color: white;
    table-layout: fixed;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
  }
  
  .table-header {
    padding: 0.5rem;
    background-color: rgb(242, 237, 245);
    font-weight: bold;
    font-size: 0.7rem;
    border: 1px solid #ccc;
  }
  
  .table-data {
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    word-wrap: break-word;
    text-align: center;
    font-size: 0.65rem;
  }
}
