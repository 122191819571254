body {
  margin: 0;
  padding: 0;
  /* background: url('./hjk7.jpg') no-repeat center center fixed; */
  background-size: cover;
  background-position: center;
  height: 100vh;
  overflow: hidden; /* Prevents any scroll bars due to the background image */
}

.LPContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 110vh;
}

.LPCenter {
  text-align: center;
}

.LPText {
  color:white;
  font-family:sans-serif;
  font-size: 1rem;
  margin: 10px 0 0 10px;
  white-space: nowrap;
  overflow: hidden;
  width: 30em;
  animation: type 4s steps(60, end);
}

.LPText:nth-child(2) {
  animation: type2 8s steps(60, end);
}

.LPLink {
  color: lime;
  text-decoration: none;
}

.LPLine {
  animation: blink 1s infinite;
}

/* @keyframes type {
  from {
    width: 0;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  50% {
    width: 0;
  }
  100% {
    width: 100%;
  }
} */

@keyframes blink {
  to {
    opacity: 0;
  }
}

::selection {
  background: black;
}
@media (max-width: 768px) {
  .LPText {
    color: white;
    font-size: 0.9rem;
    margin: 10px 0 0 10px;
    white-space: nowrap;
    overflow: hidden;
    width: 30em;
    animation: type 4s steps(60, end);
  }

  .LPContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
  }
}