.why-choose-us {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    position: relative;
    overflow: hidden;
    color: #fff;
    background: transparent;
    margin-bottom: 2rem;
  }
  
  .container {
    max-width: 800px;
    padding: 20px;
    text-align: center;
    background: linear-gradient(115.3deg, rgb(244, 247, 246) 7.4%, rgb(255, 255, 255) 103.1%);
    border-radius: 15px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2), -10px -10px 10px rgba(255, 255, 255, 0.2);
    transform: translateY(50px);
    opacity: 0;
    transition: transform 1s ease, opacity 0.5s ease;
  }

  .WhyTitle{
   font-size: clamp(2rem, 6vw + 1rem, 2rem);
	font-weight: 900;
  margin-bottom: auto;
  font-family: Poppins;
  font-weight: 600;
  background: var(--global-text-gradient);  
	color: transparent;
	-webkit-background-clip: text;
  }
  
  .container:hover {
    transform: translateY(0);
    opacity: 1;
    background: aliceblue;
    cursor: pointer;
  }
  
  h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: black;
  }
  
  p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: black;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .why-choose-us {
      min-height: 90vh;
      background: #fff;
    }
  
    .container {
      padding: 10px;
      box-shadow: none;
    }
  
    h1 {
      font-size: 28px;
    }
  
    p {
      font-size: 16px;
    }
  }
  
  /* Animation when in viewport */
  .why-choose-us.visible .container {
    transform: translateY(0);
    opacity: 1;
  }
  