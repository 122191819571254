/* FullScreenEvent.css */
.full-screen-event {
    position: fixed;
    margin-top: 0%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(51, 50, 50, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    overflow: auto; /* Enable scrolling when content exceeds the modal's height */
  }
  
  .event-modal {
    background: white;
    border-radius: 20px;
    padding: 1rem;
    width: 80%;
    max-width: 800px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: var(--global-text-gradient);
    color: white;
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .event-modal h3 {
    font-weight: 500;
    font-size: 20px;
    margin: auto;
    margin-bottom: 1rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background: var(--global-text-gradient);
    color: transparent;
    -webkit-background-clip: text;
  }
  
  .EventDate {
    font-size: 16px;
    color: grey;
    margin: 10px 0;
  }
  
  .event-modal .eventEU-imageEU {
    position: relative;
    overflow: hidden;
  }
  
  .event-modal .eventEU-imageEU img {
    width: 50%;
    min-width: 10rem;
    min-height: 10rem;
    height: auto;
    object-fit: cover;
    transition: transform 0.5s;
  }
  
  .event-modal .eventEU-imageEU img:hover {
    transform: scale(1.1); /* Zoom in by 10% on hover */
  }
  
  .event-modal .eventEU-imageEU:hover {
    z-index: 1;
  }
  
  .event-modal .eventEU-imageEU:not(:hover) {
    z-index: 0;
  }
  
  .event-description {
    font-size: 15px;
    color: grey;
    margin: 10px 0;
    text-align: justify;
    line-height:1.1rem;
    justify-content: space-between;
    overflow-y: auto; /* Enable vertical scrolling when content exceeds the modal's height */
    max-height: 300px; /* Set a maximum height for the description area */
  }
  
  @media (max-width: 768px){
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: var(--global-text-gradient);
    color: white;
    border: none;
    border-radius: 10px;
    padding: 2px 8px;
    cursor: pointer;
  }


.ET{
 font-size: 1rem;
 display: flex;
 flex-wrap: wrap;
}}  