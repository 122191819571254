/* Container for the entire appointment form */
.appointmentcontainer {
  animation: fadeInUp 1s ease;
  max-width: 400px;
  margin: 0 auto;
  padding: 1rem;
  padding-right: 2rem;
  margin-bottom: 2rem;
  height: auto;
  text-align: center;
  background: linear-gradient(to top, #dfe9f3 0%, white 100%);
  border-radius: 8px; /* Add some border-radius for a subtle 3D effect */
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2); /* Increase shadow size for a 3D effect */
}

.aboveplaceholder{
  color: black;
  font-size: 0.7rem;
}

/* Title style */
.title22 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  background: var(--global-text-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Input field styles */
div {
  margin: 10px 0;
}

input[type="text"],
input[type="tel"],
input[type="email"],
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f8f8f8;
  transition: border-color 0.3s;
}

/* Improved select style */
select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cpath fill='%23000' d='M4 7l-4-4h8z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 30px;
}

input[type="text"]:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
select:focus {
  outline: none;
  border-color: #007BFF;
}

/* Label styles */
h3.insteadofplaceholder {
  text-align: left;
  color: white;
  margin-bottom: 6px;
  font-size: 0.7rem;
}

/* Button styles */
button {
  background: var(--global-text-gradient);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}


/* Acknowledgment message style */
.acknowledgment {
  font-size: 18px;
  margin: 20px 0;
  color: #333;
}

/* Loading animation */
button:disabled::before {
  content: 'Loading...';
  display: inline-block;
  font-size: 16px;
  margin-right: 10px;
  border: 4px solid #fff;
  border-top: 4px solid #007BFF;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
