.offers-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: transparent;
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
}

.offers-page h1 {
  font-size: clamp(2rem, 6vw + 1rem, 2rem);
  font-weight: 900;
  margin-bottom: auto;
  font-family: Poppins;
  font-weight: 600;
  background: var(--global-text-gradient);
  color: transparent;
  -webkit-background-clip: text;
}

.offer-list ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.offer-list li {
  width: 45%; /* Two offers per row with some spacing */
  background:whitesmoke;
  border: 1px solid whitesmoke;
  border-radius: 10px;
  margin: 0 0 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: transform 0.2s;
}

.offer-list li:hover {
  transform: scale(1);
}

.offer-list li h3 {
  font-size: 1rem;
  margin-bottom: auto;
  font-family: Poppins;
  font-weight:600;
  background: var(--global-text-gradient);
  color: transparent;
  -webkit-background-clip: text;
}

.offer-list .OfferDate {
  display: none;
  font-size: 14px;
  color: #777;
  margin: 10px 0;
}

.offer-image img {
  width: 100%;
  height: auto;
  max-width: 119.88mm;
  max-height: 170.1mm;
  border-radius: 10px;
}

.offer-description {
  font-size: 16px;
  margin-top: 20px;
  color: #444;
}

.btttn {
  background: var(--global-text-gradient);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  margin-top: 0%;
  margin-left: 0%;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btttn:hover {
  background-color: #005abf;
}

/* Media query for iPhone 11 screen size (414px width) */
@media screen and (max-width: 414px) {
  .offers-page {
    padding: 10px;
  }

  .offers-page h1 {
    font-size: 1.5rem;
    margin-bottom: auto;
  }

  .offer-list li {
    width: 100%; /* Full width on small screens */
    box-sizing: border-box;
  }

  .offer-list li h3 {
    font-size: 0.9rem;
    margin-top: auto;
  }

  .offer-list .OfferDate {
    font-size: 12px;
    margin: 5px 0;
  }

  .offer-description {
    font-size: 14px;
    margin-top: 10px;
  }

  .btttn {
    font-size: 14px;
    padding: 10px 20px;
  }
}
