/* Styles for the introduction container */
.introduction-container {
  background: transparent;
  height: auto;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 2rem;
  position: relative;
  overflow: hidden;
  perspective: 1000px;
  perspective-origin: center;
  margin-bottom: 2rem;
}

/* Styles for the introduction content */
.introduction-content {
  max-width: 800px;
  padding: 20px;
  animation: fadeIn 1s ease-in-out;
}

/* Styles for the intro title */
.intro-title {
  font-size: 1.7rem;
	font-weight: 900;
  margin-bottom: 0%;
  font-family: Poppins;
  font-weight: 600;
	background: var(--global-text-gradient);
	color: transparent;
	-webkit-background-clip: text;
  
}

/* Styles for the intro description */
.intro-description {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  text-shadow: none;
  color: grey;
}

/* Styles for all the buttons (WhatsApp, Book an Appointment, Call, Locate Us) */
.intro-button,
.call-button,
.whatsapp-button,
.locate-us-button {
  background: var(--global-text-gradient);
  color: #fff;
  border: none;
  padding: 10px 15px; /* Adjusted padding for consistency and responsiveness */
  margin: 0.5rem; /* Adjusted margin for consistency and responsiveness */
  font-size: 1rem; /* Set the same font size for all buttons */
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.locate-us-button {
  color: #fff;
  border: none;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  background: radial-gradient(circle at 10% 20%, rgb(0, 47, 6) 0%, #70d5ec 90%);
  -webkit-background-clip: text;
  color: transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Hover styles for all the buttons */
.intro-button:hover,
.call-button:hover,
.whatsapp-button:hover,
.locate-us-button:hover {
  background: var(--global-text-gradient-hover);
  color: darkslateblue;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

/* Keyframes for fade-in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Add styles for the Call button */
.call-button {
  color: #fff;
  border: none;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  background: radial-gradient(circle at 10% 20%, rgb(0, 47, 6) 0%, #70d5ec 90%);
  -webkit-background-clip: text;
  color: transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Hover styles for the Call button */
.call-button:hover {
  background: var(--global-text-gradient-hover);
  color: darkslateblue;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

/* Add styles for the WhatsApp button */
.whatsapp-button {
  color: #fff;
  border: none;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  background: var(--global-text-gradient);
  -webkit-background-clip: text;
  color: transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Hover styles for the WhatsApp button */
.whatsapp-button:hover {
  background: var(--global-text-gradient-hover);
  color: darkslateblue;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

/* Responsive Styles for iPhone screen */
@media (max-width: 414px) {
  .intro-button,
  .call-button,
  .whatsapp-button,
  .locate-us-button {
    padding: 10px 20px; /* Adjusted padding for iPhone screen */
    margin: 1rem; /* Adjusted margin for consistency and responsiveness */
    font-size: 0.9rem; /* Adjusted font size for iPhone screen */
  }

  .introduction-container {
    height: auto;
  }
}



.search-button {
  border: none;
  color: #fff;
  background-image: linear-gradient(30deg, #0400ff, #4ce3f7);
  border-radius: 20px;
  background-size: 100% auto;
  font-family: inherit;
  font-size: 17px;
  padding: 0.6em 1.5em;
 }
 
 .search-button:hover {
  background-position: right center;
  background-size: 200% auto;
  -webkit-animation: pulse 2s infinite;
  animation: pulse512 1.5s infinite;
 }
 
 @keyframes pulse512 {
  0% {
   box-shadow: 0 0 0 0 #05bada66;
  }
 
  70% {
   box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
  }
 
  100% {
   box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
  }
 }


 /* new button hover */

 