/* Base styles for larger screens */
.footer {
  background: var(--global-text-gradient);
  color: white;
  padding: 50px 0;
  font-family: 'Roboto', sans-serif;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.LoginButton{
  color: white;
  text-decoration: none;
  font-size: 14px;
  margin-right: 20px;
}
/* .LoginButton:hover{
  background-color: #fff;
  color: black;
  padding: 8px;

} */

.footer-logo {
  display: flex;
  align-items: center;
}

.footer-logo img {
  width: 9rem;
  height: auto;
  margin-right: 10px;
  background-color: white;
  padding-left: 0.5rem;
}

.footer-logo h2 {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.footer-contact h3,
.footer-social h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: white;
}

.footer-contact p {
  font-size: 14px;
  margin: 0;
  color: white;
}

.contact-info.phone {
  font-size: 14px;
  margin: 0;
  color: white;
}

.contact-info.email {
  font-size: 14px;
  margin: 0;
  color: white;
}

.footer-social ul {
  list-style: none;
  padding: 0;
  color: white
}

.social-link {
  color: #a8b2d1;
  text-decoration: none;
  font-size: 14px;
  margin-right: 20px;
  color: white;
}

.social-link:hover {
  color: white;
}

/* Style links within the footer-contact section */
.footer-contact p a {
  color: white;
  text-decoration: none;
  font-size: 14px;
}

.footer-contact p a:hover {
  color: white;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo {
    text-align: center;
    margin-bottom: 20px;
  }

  .footer-logo img {
    margin-right: 0;
  }

  .footer-contact,
  .footer-social {
    text-align: center;
  }

  .social-link {
    margin-right: 10px;
  }
}

/* Deeply responsive styles for various screen types */
@media (max-width: 480px) {
  .footer-contact h3,
  .footer-social h3 {
    font-size: 16px;
  }

  .footer-contact p {
    font-size: 12px;
  }

  .footer-social ul {
    margin-top: 10px;
  }

  .social-link {
    font-size: 12px;
    margin-right: 10px;
  }

  /* Style links within the footer-contact section for smaller screens */
  .footer-contact p a {
    font-size: 12px;
  }
}
