/* Apply styles to the entire application */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  

  
}

.App {
  padding: 0.5rem 3.5rem;
  overflow: hidden;

}

:root {
  --clr:transparent;
  --global-text-gradient: #0071e3;

  --global-text-gradient-hover: radial-gradient(circle at 10% 20%, rgb(184, 249, 193) 0%, #f1f8f9 90%);
  --cmn:#0000ff;


  
}



/* Apply responsive styles */
@media screen and (max-width: 480px) {
  .App {
    padding: 0.5rem 1rem;
  }
}


