/* Navbar.css */

body{
  background: black;
}


.icon {
  transition: transform 0.3s;
  animation: rotateIcon 0.3s ease-in;
  margin: auto;
  color: rgb(3, 85, 23);
	
}

.icon.fa-times {
  transform: rotate(90deg);
}

@keyframes rotateIcon {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(45deg);
  }
  75% {
    transform: rotate(75deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

/* Your existing styles... */

.navbar {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  /* Add a background style if needed */
}

.navbar-logo {
  width: 12rem;
  height: auto;
  margin-top: -0.5rem;
  margin-left: -1rem;
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.navbar-toggle.active .bar:nth-child(1),
.navbar-toggle.active .bar:nth-child(3) {
  transform: translateY(0) rotate(45deg);
}

.navbar-toggle.active .bar:nth-child(2) {
  opacity: 0;
}

.bar {
  width: 100%;
  height: 4px;
  background-color: #fff;
  transition: transform 0.5s, opacity 0.3s;
}

.navbar-menu {
  list-style-type: none;
  display: flex;
  margin: 0;
}

.navbar-item {
  margin-right: 20px;
}

.navbar-menu a {
  text-decoration: none;
  color:white;
  font-weight:100;
  font-family: sans-serif;
  position: relative;
  padding: 5px;
  transition: all 0.7s ease-in-out;
  font-size: 0.9rem;
}

.navbar-menu a::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: transparent;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.navbar-menu a:hover {
  color: white;
  padding: 5px;
}

.navbar-menu a:hover::before {
  visibility: visible;
  background-color: blue;
  transform: scaleX(0.9);
}

@media (max-width: 768px) {
  .navbar-toggle {
    display: flex;
    order: 2;
    color: black;
    transition: color 0.3s ease;
    position:fixed;
    left: 18rem;
    top:1.5rem;
    /* visibility: hidden; */
  }

  .navbar-menu {
    flex-direction: column;
    position: absolute;
    top: 5rem;
    right: 0;
    background-color: #f3ebeb;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    z-index: 13;
  }

  .navbar-menu.active {
    opacity: 1;
    visibility: visible;
    padding-top: 7rem;
    background: black;
  }

  .navbar-item {
    margin: 10px 0;
    font-weight: lighter;
  }

  .navbar-item a {
    color: white;
  }

  .navbar-logo {
    width: 10rem;
    height: auto;
  }
  .icon {
    transition: transform 0.3s;
    animation: rotateIcon 0.3s ease-in;
    margin: auto;
    color: rgb(245, 249, 246);
    
    
  }
  
  .icon.fa-times {
    transform: rotate(90deg);
  }
}
