/* CSS for Events page */
.events-page {
  text-align: center;
  font-size: 1rem;
  font-family: Poppins;
  overflow: hidden;
}

.events-page h1 {
  font-size: clamp(2rem, 6vw + 1rem, 2rem);
  font-weight: 900;
  margin-bottom: auto;
  font-family: Poppins;
  font-weight: 600;
  background: var(--global-text-gradient);
  color: transparent;
  -webkit-background-clip: text;
}

.event-list ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Start from the left */
  margin: 0;
  padding: 0;
  list-style: none;
}

.event-list ul li {
  flex: 0 0 calc(25% - 20px); /* 25% to fit 4 items in a row with some spacing */
  margin: 10px; /* Adjust the spacing between items as needed */
  box-sizing: border-box; /* Include padding and border in item width */
  text-align: center; /* Optionally center-align items */
}

/* Media query for screens smaller than 768px (e.g., mobile devices) */
@media (max-width: 768px) {
  .event-list ul li {
    flex: 0 0 calc(90% - 1px); /* 50% to fit 2 items in a row for smaller screens */
  }
}

.event-list li {
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2), -10px -10px 10px rgba(255, 255, 255, 0.2);
  background: white;
  border: 1px solid linear-gradient(to bottom, #87e6fb 0%, #dd3ecb 100%);
  box-shadow: var(--boxShadow);
  border-radius: 20px;
  padding: 1rem;
  transition: 0.5s;
  width: 20rem;
  height: auto;
  margin: 1rem;
}

.event-list .eventEU-imageEU {
  position: relative;
  overflow: hidden;
}

.event-list .eventEU-imageEU img {
  width: 100%;
  min-width: 10rem;
  min-height: 10rem;
  height: auto;
  object-fit: cover;
  transition: transform 0.5s;
}

.event-list .eventEU-imageEU img:hover {
  transform: scale(1.1); /* Zoom in by 10% on hover */
}

.event-list .eventEU-imageEU:hover {
  z-index: 1;
}

.event-list .eventEU-imageEU:not(:hover) {
  z-index: 0;
}

.event-list h3 {
  flex-wrap: wrap;
  font-weight: 500;
  font-size: 20px;
  margin: auto;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  background: var(--global-text-gradient);
  color: transparent;
  -webkit-background-clip: text;
}

.EventDate {
  display: none;
}

.event-list p {
  font-size: 15px;
  color: grey;
  margin: 10px 0;
}

.event-list .event-description {
  font-size: 15px;
  color: grey;
  margin: 10px 0;
  text-align: justify;
}

.btn {
  --color: white;
  --color2: rgb(248, 248, 250);
  padding: 0.8em 1.75em;
  background: var(--global-text-gradient);
  border-radius: 6px;
  border: 3px;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  font-weight: 300;
  font-size: 0.5rem;
  font-family: 'Roboto', 'Segoe UI', sans-serif;
  text-transform: uppercase;
  color: white;
}

.btn::after,
.btn::before {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  transform: skew(90deg) translate(-50%, -50%);
  position: absolute;
  inset: 50%;
  left: 25%;
  z-index: -1;
  transition: 0.5s ease-out;
  background-color: rgb(40, 65, 2);
}

.btn::before {
  top: -50%;
  left: -25%;
  transform: skew(90deg) rotate(180deg) translate(-50%, -50%);
}

.btn:hover::before {
  transform: skew(45deg) rotate(180deg) translate(-50%, -50%);
}

.btn:hover::after {
  transform: skew(45deg) translate(-50%, -50%);
}

.btn:hover {
  background: var(--global-text-gradient);
}

.btn:active {
  filter: brightness(0.7);
  transform: scale(0.98);
}

/* Responsive Styles for iPhone 7 and 11 screens */
@media screen and (max-width: 480px) {
  .event-list .eventEU-imageEU img {
    width: 100%;
    min-width: 10rem;
    min-height: 10rem;
    height: auto;
    object-fit: cover;
  }

  .event-list ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
