/* General styles for all screen sizes */
.experience {
    animation: fadeInUp 1s ease-in-out;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem; /* Adjust gap for better spacing */
    margin:auto;
    height: auto;
    color: rgb(43, 41, 41);
    /* text-shadow: 1px 1px 2px rgba(41, 39, 39, 0.5); */
  }
  
  .achievement {
    flex: 1; /* Equal width for all achievements */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; /* Center text within the achievements */
  }
  
  .circle {
    width: 5rem;
    height: 5rem;
    background: white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    position: relative;
  }
  
  .circle::before {
    border-radius: 100%;
    content: '';
    /* background-image: linear-gradient(135deg, #FEED07 0%, #8a50fe 5%, #ED00AA 15%, #2FE3FE 50%, #8900FF 100%); */
    background-image: linear-gradient(135deg, #e9fae6 0%, #5401fc 5%, #93f903 15%, #75d6eb 50%, #006400 100%);
    top: -8px;
    left: -8px;
    bottom: -8px;
    right: -8px;
    position: absolute;
    z-index: -1;
    box-shadow: var(--boxShadow);
    animation-duration: 2s;
    animation-name: rotate;
    animation-iteration-count: infinite;
  }
  
  .achievement span:nth-of-type(2) {
    color: var(--orange);
  }
  
  /* The below is the element enabling rotation */
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Media Queries */
  @media screen and (max-width: 480px) {
    .experience {
      gap: auto;
      height: auto;
    }
  
    .achievement {
      flex: 1;
      text-align: center;
      transform: scale(0.8); /* Scale down items to fit all in one row */
    }
  
    .circle {
      width: 4rem;
      height: 4rem;
      font-size: 0.8rem;
      margin-bottom: 0.5rem;
    }
    .HLdescription{
        font-size: 0.9rem;
    }
 
  }
  
  @media only screen and (max-height: 667px) {
    
  }
  