/* MAP.css */
.button-container-MAP {
  position: relative;
  animation: fadeInUp 1s ease;
  text-align: center;
  padding: 2rem;
  background-color: whitesmoke;
  height: auto;
  margin-bottom: 2rem;
}

.page-title-MAP {
  animation: fadeInUp 2s ease;
  font-size: 2.5rem;
  font-family: Poppins;
  margin-bottom: 2rem;
  font-weight: bolder;
  background: -webkit-linear-gradient(
    219.3deg,
    rgb(149, 0, 199) 4.7%,
    rgb(130, 0, 226) 22.9%,
    rgb(95, 0, 199) 43.2%,
    rgb(70, 0, 199) 63.9%,
    rgb(5, 0, 199) 85.1%,
    rgb(0, 95, 199) 106.8%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.MAPlogoutbutton{
  background: red;

}

/* Button styles */
.button-group-MAP {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.button-primary-MAP,
.button-secondary-MAP,
.button-tertiary-MAP,
.MAPlogoutbutton {
  background: #007BFF;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.2s, transform 0.2s;
  margin: 0.5rem;
}

.button-primary-MAP:hover,
.button-secondary-MAP:hover,
.button-tertiary-MAP:hover,
.MAPlogoutbutton:hover {
  background: #0056b3;
  transform: scale(1.05);
}

/* Responsive styles */
@media (max-width: 768px) {
  .button-group-MAP {
    flex-direction: column;
  }
}
