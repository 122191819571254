.admin-doctor-management {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  input {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
  }
  
  .buttons {
    display: flex;
    gap: 10px;
  }
  
  button {
    padding: 8px;
    cursor: pointer;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  li button {
    margin-left: 10px;
  }
  