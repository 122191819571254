@import url('https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@300;400;500;600&display=swap');

.about-us-wrapper::-webkit-scrollbar { display: none; }

.about-us-wrapper {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background: black;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.about-us-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 5rem;
  padding: 80px 24px;
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
  color: #1d1d1f;
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
  box-sizing: border-box;
}

.about-us-header {
  text-align: center;
  margin-bottom: 60px;
  padding-top: 40px;
}

.title2 {
  font-size: clamp(32px, 4vw, 37px);
  line-height: 1.1;
  font-weight: 600;
  letter-spacing: -0.002em;
  margin: 0 0 20px;
  background: var(--global-text-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header-subtitle {
  font-size: clamp(16px, 2.5vw, 20px);
  line-height: 1.3;
  font-weight: 400;
  color: #86868b;
  margin: 0;
  padding: 0 clamp(10px, 2vw, 20px);
}

.about-us-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.large-text {
  font-size: clamp(22px, 3vw, 28px);
  line-height: 1.4;
  font-weight: 400;
  margin: clamp(24px, 4vw, 40px) 0;
  color: #ababb0;
}

.content-section {
  margin-top: clamp(30px, 4vw, 40px);
}

.content-section p {
  font-size: clamp(16px, 2vw, 20px);
  line-height: 1.6;
  font-weight: 400;
  font-size: 0.9rem;
  color: #909093;
  margin-bottom: clamp(20px, 3vw, 28px);
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s ease-out forwards;
}

.content-section p:last-child { margin-bottom: clamp(40px, 6vw, 60px); }
.content-section p:nth-child(1) { animation-delay: 0.2s; }
.content-section p:nth-child(2) { animation-delay: 0.4s; }
.content-section p:nth-child(3) { animation-delay: 0.6s; }

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Breakpoints */
@media (max-width: 834px) {
  .about-us-container { padding: 60px 20px; }
}

@media (max-width: 480px) {
  .about-us-container {
    padding: 40px 16px;
    margin-bottom: 10rem;
  }
  .about-us-header {
    margin-bottom: 40px;
    padding-top: 20px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html { scroll-behavior: smooth; }