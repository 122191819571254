.login-form {
    animation: fadeIn 1s ease-in-out;
    background: linear-gradient(to right, rgb(253, 252, 253), rgb(1, 171, 92));
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      max-width: 300px;
      margin: 0 auto;
      padding: 20px;
      text-align: center;
      padding-right: 2rem;
      margin-bottom: 2rem;
    }

    .forgotPASS{
        background: transparent;
        color: white;
        font-size: 0.5rem;
        cursor: pointer;
    }

    .forgotPASS:hover{
        background: white;
        color: black;
        font-size: 0.5rem;
        cursor: pointer;
        padding: 0.6rem;
    }




    .alert{
        background: transparent;
        color: red;
        font-size: 1rem;
    }
    
    .ALlogintitle{
      color: white;
      font-size: 1rem;
    }
    .login-form h2 {
      color: grey;
      font-size: 24px;
      margin-bottom: 20px;
    }
    
    .login-form input {
      width: 100%;
      padding: 7px;
      margin: 10px 0;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;
    }
    
    .login-form button {
      background: linear-gradient(70.6deg, rgb(172, 30, 255) 0.3%, rgb(65, 35, 251) 55.8%, rgb(35, 251, 224) 105.1%);
      border: none;
      border-radius: 4px;
      color: white;
      cursor: pointer;
      font-size: 16px;
      padding: 7px 20px;
      transition: background-color 0.3s ease;
    }
    
    .login-form button:hover {
      background: radial-gradient(circle at -0.2% 48.9%, rgb(32, 233, 104) 0%, rgb(13, 167, 236) 99.8%);
    }
    